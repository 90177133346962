/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* FOOTER */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

.site__footer {
    margin-top: 8rem;
    padding-top: 0rem;
    position: relative;

    &__inner {
        color: $light;
        background: $gray1;
    }

    // Scroll to Top
    &__scrolltotop {
        position: absolute;
        top: 6rem;
        right: 2rem;

        &__btn {
            appearance: none;
            background: none;
            border: none;
            cursor: pointer;

            span {
                text-transform: uppercase;
                font-weight: bold;
                color: $light;
            }

            i {
                @include flex(inline-flex, row, nowrap, center, center);
                background: $red;
                color: $light;
                border-radius: 4px;
                padding: 0.5rem;
                transition: $trans;
                margin-left: 1rem;
            }

            &:hover {
                i {
                    background: $dark;
                }
            }
        }
    }

    &__logo {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);

        padding: 1rem 3rem;
        // height: 8rem;
        background-color: $light;
        box-shadow: 0 2px 12px rgba($dark, .2);

        // @include flex(flex, row, nowrap, center, center);
    }

    &__main {
        padding-top: 6rem;
        @include flex(flex, row, nowrap, flex-start, stretch);

        &__column {
            width: calc(100% / 12 * 2);
            max-width: calc(100% / 12 * 2);
            flex-grow: 0;
            flex-shrink: 0;

            &__title {
                font-family: $ff1;
                font-weight: 600;
                // font-style: italic;
                color: $light;
                margin-bottom: 2rem;

                a {
                    color: inherit;
                    text-decoration: none;
                }
            }

            &__list {
                list-style: none;
                padding: 0;
                margin: 0;

                &__item {
                    list-style: none;

                    &__link {
                        text-decoration: none;
                        color: inherit;
                        font-family: $ff2;
                        font-size: ptr(14px, $fz);
                        font-weight: normal;
                        line-height: 2;
                        transition: $trans;

                        &:hover {
                            color: $red;
                        }
                    }
                }
            }

            &.gamme {
                width: calc(100% / 12 * 3);
                max-width: calc(100% / 12 * 3);

                .site__footer__main__column__list {
                    @include flex(flex, row, wrap, flex-start, stretch);

                    &__item {
                        display: inline-block;
                        width: calc(100% / 3);
                    }
                }
            }

            &.menu {
                .site__footer__main__column__list {
                    margin-top: 1em;

                    &__item {
                        &__link {
                            font-size: ptr(16px, $fz);
                            font-family: $ff2;
                            font-weight: 600;
                            font-style: italic;
                            color: $light;
                            margin-bottom: 2rem;
                            transition: $trans;

                            &:hover {
                                color: $red;
                            }
                        }
                    }
                }
            }

            &.social {
                margin-left: calc(100% / 12 * 1);

                .site__footer__main__column__list__item__link {
                    i {
                        font-size: ptr(40px, $fz);
                    }
                }
            }
        }
    }

    &__bottom {
        padding: 1rem;

        p {
            text-align: right;
            font-family: $ff2;
            font-size: ptr(14px, $fz);
            font-weight: normal;

            a {
                color: inherit;
                text-decoration: none;
                transition: $trans;

                &:hover {
                    color: $red;
                }
            }
        }
    }
}

/* ----------------------------------------------------- */
/* COOKIES */
/* ----------------------------------------------------- */
#tx_cookies {
    z-index: 990;

    &_inner {
        padding: 0;
        @include flex(flex, row, nowrap, flex-start, center);

        > h3,
        > p,
        > form {
            font-size: 1rem;
            margin: 0 2rem 0 0;
        }
    }

    button, 
    input[type="submit"] {
        appearance: none;
        margin: 0;
        font-size: 1rem;
    }

    #tx_cookies_close {
        margin: 0;
        top: 0px !important;
    }
}

#tx_cookies_inner {
    padding: 0 20px !important;
}


/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* TABLET : #{$screen-sm} px */
/* ----------------------------------------------------- */
@media (max-width: 768px) {

    .site__footer {
        &__scrolltotop {
            &__btn {
                span { display: none;}
            }
        }

        &__main {
            flex-wrap: wrap;

            &__column {
                width: calc(100% / 12 * 4);
                max-width: calc(100% / 12 * 4);
                padding-right: 0.5rem;

                &.gamme {
                    width: calc(100% / 12 * 4);
                    max-width: calc(100% / 12 * 4);

                    .site__footer__main__column__list__item {
                        width: 100%;
                    }
                }

                &.menu {
                    width: calc(100% / 12 * 6);
                    max-width: calc(100% / 12 * 6);
                }

                &.social {
                    margin-left: 0;
                    width: calc(100% / 12 * 6);
                    max-width: calc(100% / 12 * 6);
                }
            }
        }
    }

}

/* ----------------------------------------------------- */
/* TABLE : #{$screen-xs} px */
/* ----------------------------------------------------- */
@media (max-width: 320px) {

    .site__footer {
        &__main {
            &__column {
                width: 100%;
                max-width: 100%;
                padding-right: 0.5rem;

                &.gamme {
                    width: 100%;
                    max-width: 100%;

                    .site__footer__main__column__list__item {
                        width: 100%;
                    }
                }

                &.menu {
                    width: 100%;
                    max-width: 100%;
                }

                &.social {
                    margin-left: 0;
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }

}