@import 'base/variables';
@import 'base/mixin';
@import 'base/animations';

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/icomoon.eot');
  src:  url('../fonts/icomoon/icomoon.eot#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.ttf') format('truetype'),
    url('../fonts/icomoon/icomoon.woff') format('woff'),
    url('../fonts/icomoon/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icn {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icn-linkedin:before {
  content: "\e905";
}
.icn-arrow-slim-right:before {
  content: "\e906";
}
.icn-arrow-slim-down:before {
  content: "\e907";
}
.icn-arrow-slim-left:before {
  content: "\e908";
}
.icn-arrow-slim-top:before {
  content: "\e909";
}
.icn-calendar2:before {
  content: "\e90a";
}
.icn-arrow-right:before {
  content: "\e900";
}
.icn-arrow-left:before {
  content: "\e904";
}
.icn-contact:before {
  content: "\e901";
}
.icn-k:before {
  content: "\e902";
}
.icn-search:before {
  content: "\e903";
}

#tx_cookies {
    /*font-size: 2em;*/
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99;
}

#tx_cookies_inner {
  padding: 20px 20px !important;
}

#tx_cookies_hide {
  text-align: right;
}

#tx_cookies #tx_cookies_close {
    position: absolute;
    right: 20px !important;
    top: 20px !important;
    z-index: 99;
}

#tx_cookies button, #tx_cookies input[type="submit"] {
    border: 0;
    background: #cd1c2c !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 19px;
    margin: 10px 0;
    padding: 10px !important;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: $ff1;
    overflow-x: hidden;
}

h2 {
  font-size: ptr(46px, $fz);
}

.inner2 {
  max-width: 1500px;
  margin: 0 auto;
  padding: 0rem 1rem;
}

/* ----------------------------------------------------- */
/* < 992px */
/* ----------------------------------------------------- */
@media screen and (max-width: 992px) {
  body.home {
    font-size: 16px;
  }

  h2 {
    font-size: ptr(32px, $fz);
  }

  h3 {
    font-size: ptr(24px, $fz);
  }
}

@media screen and (max-width: 740px) {
  body.home {
    #home-content {
      padding-top: 0;
      overflow: hidden;
    }
  }
}

@media screen and (max-width: 576px) {
  body.home {
    font-size: 16px;
  }

  h2 {
    font-size: ptr(28px, $fz);
  }

  h3 {
    font-size: ptr(20px, $fz);
  }
}

@import 'common/header';
@import 'common/sections';
@import 'common/footer';