section {

    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* SLIDER */
    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    &.slider {
        width: 100%;
        // height: 50vw;
        padding: 2rem 2rem 0 2rem;
        position: relative;

        .slider {
            &__video {
                position: absolute;
                // top: ptr(115px, $fz);
                left: calc(45% - 2rem);
                right: 2rem;
                bottom: 0rem;
                z-index: 0;
                width: auto;
                height: 0;
                padding-top: calc( 56.25% * .55);
                overflow: hidden;
                opacity: 1;
                transition: opacity .4s linear;

                video {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    // height: 100%;
                    object-fit: contain;
                    object-position: bottom center;
                }

                body.scrolled & {
                    opacity: 1;
                }

                &__volume {
                    position: absolute;
                    bottom: 3rem;
                    right: 3rem;
                    -webkit-appearance: none;
                    border: none;
                    background: $light;
                    color: $dark;

                    &.off {
                        .slider__video__volume__on { display: block; }
                        .slider__video__volume__off { display: none; }
                    }
                    &.on {
                        .slider__video__volume__on { display: none; }
                        .slider__video__volume__off { display: block; }
                    }
                }
            }

            &__container {
                // position: absolute;
                // top: 2rem;
                // bottom: 2rem;
                // left: 2rem;
                position: relative;
                width: calc(45% - 2rem);
                min-height: 35vw;
                background-color: $red;
                // transition: $trans;

                .slider__title {
                    padding: 9rem 4rem 0;

                    h2 {
                        font-size: ptr(38px, $fz);
                        text-transform: uppercase;
                        line-height: 1.25;

                        b, span { 
                            color: $light;
                        }
                    }

                    &:after {
                        content: '';
                        display: block;
                        margin-top: 1rem;
                        height: 3px;
                        width: 50%;
                        background: $light;
                    }
                }

                &__inner {
                    .owl-stage-outer {
                        height: 100%;
                        overflow: hidden;

                        .owl-stage {
                            height: 100%;

                            .owl-item {
                                width: 100%;
                                height: 100%;
                                position: relative;
                                float: left;
                                overflow: hidden;
                                background: url('../img/dots.png') center right no-repeat;

                                .slider__container__item {
                                    position: relative;
                                    padding: 0 4rem 5rem 4rem;

                                    &__text {
                                        margin-top: 4rem;
                                        font-size: ptr(24px, $fz);
                                        color: $light;
                                        font-weight: bold;
                                        line-height: 1.25;

                                        span {
                                            color: $dark;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                /* ----------------------------------------------------- */
                /* replie */
                /* ----------------------------------------------------- */
                // body.scrolled & {
                //     opacity: 0;
                //     visibility: hidden;
                //     transform: translateX(-100%);
                // }
            }

            &__nav {
                position: absolute;
                z-index: 30;
                left: 6rem;
                bottom: 2rem;

                .owl-prev,
                .owl-next {
                    @include flex(inline-flex, row, nowrap, center, center);
                    width: ptr(32px, $fz);
                    height: ptr(32px, $fz);
                    padding: 4px;
                    background-color: $gray;
                    border: none;
                    appearance: none;
                    color: $light;
                    margin-right: 0.5rem;
                    font-size: ptr(20px, $fz);
                    cursor: pointer;
                    transition: $trans;

                    &:hover {
                        background-color: $dark;
                    }
                }

                body.scrolled & {
                    opacity: 0;
                }
            }

            &__anchors {
                position: absolute;
                right: 2rem;
                top: 50%;
                transform: translateY(-50%);

                &__list {
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    &__item {
                        list-style: none;
                        text-transform: uppercase;
                        color: $dark;
                        font-weight: bold;
                        transform: rotate(90deg);
                    }
                }
            }
        }

    }


    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* SOLUTIONS */
    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    &.solutions {
        margin-top: 6rem;
        padding: 2rem 4rem;
        position: relative;
        overflow: hidden;

        &:after {
            content: '';
            display: block;
            position: absolute;
            z-index: -1;
            background-color: #eee;
            width: 50%;
            top: 0;
            bottom: 0;
            right: 0;
        }

        .inner {
            margin: 0 auto;
        }

        .solutions {
            &__title {
                text-align: center;
                text-transform: uppercase;
                
                h2 {
                    margin: 0 0 4rem 0;
                }

                b {
                    color: $red;
                }
            }

            &__slider {
                position: relative;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: -4rem;
                    width: 10%;
                    height: 100%;
                    background: url('../img/dots.png') repeat bottom right;
                }
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    right: -4rem;
                    width: 10%;
                    height: 100%;

                    background: url('../img/dots.png') repeat bottom left;
                }

                &__dots {
                    margin-top: 1rem;

                    &__nav {
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        text-align: center;
                    }
                }

                &__dot {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    display: inline-block;
                    vertical-align: top;
                    background: $dark;
                    color: $light;
                    text-transform: uppercase;
                    margin: 0rem 0.5rem;
                    cursor: pointer;
                    transition: $trans;

                    &__btn {
                        width: ptr(130px, $fz);
                        padding: 1rem;
                        appearance: none;
                        border: none;
                        background: none;
                        text-decoration: none;
                        text-transform: uppercase;
                        font-weight: bold;
                        color: $light;
                        cursor: pointer;
                    }

                    &:hover,
                    &.active {
                        background: $red;
                    }
                }

                .owl-item {
                    float: left;
                    width: 675px !important;
                }

                &__container {
                    padding: 2rem 0;
                    overflow: hidden;

                    &__items {
                        @include flex(flex, row, nowrap, flex-start, stretch);
                        transition: $cubic;
                    }

                }

                &__item {
                    @include flex(flex, row, nowrap, flex-start, stretch);
                    width: calc(100% / 1.5);
                    flex-grow: 0;
                    flex-shrink: 0;
                    padding: 0rem 0 0 5rem;
                    opacity: 0.25;

                    &__content {
                        width: 50%;
                    }

                    &__count {
                        color: $gray;
                        font-size: ptr(32px, $fz);
                        margin-bottom: 1rem;

                        b {
                            color: $red;
                            font-size: ptr(48px, $fz);
                        }
                    }

                    &__title {
                        text-transform: uppercase;
                        font-weight: bold;
                        color: $dark;
                        font-size: ptr(32px, $fz);
                        margin-bottom: 1rem;
                    }

                    &__text {
                        line-height: 1.5;
                        color: $gray;
                        margin-bottom: 1rem;
                        font-family: $ff2;
                    }

                    &__link {
                        a {
                            background: $dark;
                            color: $light;
                            text-transform: uppercase;
                            font-weight: bold;
                            padding: 1.5rem 1.5rem;
                            text-decoration: none;
                            margin-top: 1rem;
                            display: inline-block;

                            i {
                                color: $red;
                                display: inline-block;
                                margin-left: 0.5rem;
                            }

                            &:hover {
                                background-color: $red;

                                i {
                                    color: $dark;
                                    animation: balance 1s infinite;
                                }
                            }
                        }
                    }

                    &__image {
                        width: 50%;
                        transform: scale(0);
                        opacity: 0;
                        transition: $cubic;
                        @include flex(flex, row, nowrap, center, center);

                        img {
                            width: 100%;
                            height: auto;
                            display: block;
                            margin: 0 auto;
                        }
                    }


                    // Etat actif
                    &.active {
                        opacity: 1;

                        .solutions__slider__item {
                            &__image {
                                transform: scale(1);
                                opacity: 1;
                            }
                        }
                    }
                }

                &__nav {
                    &__buttons {
                        text-align: center;

                        button {
                            appearance: none;
                            border: none;
                            background: $gray1;
                            margin: 0rem 1rem;
                            border: none;
                            color: $light;
                            width: ptr(44px, $fz);
                            height: ptr(44px, $fz);
                            font-size: ptr(24px, $fz);
                            @include flex( inline-flex, row, nowrap, center, center);
                            cursor: pointer;
                            transition: $trans;
                            border-radius: 4px;

                            &:hover {
                                background-color: $dark;
                            }

                            &.inactive,
                            &[disabled] {
                                opacity: 0.25;
                            }
                        }
                    }
                }
            }
        }
    }

    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* SAVOIR FAIRE */
    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    &.savoir-faire {
        margin-top: 6rem;

        .savoir-faire {

            &__title {
                text-align: left;
                text-transform: uppercase;
                
                h2 {
                    margin: 0 0 4rem 0;
                }

                b {
                    color: $red;
                }
            }

            &__columns {
                @include flex(flex, row, nowrap, flex-start, stretch);
                margin-top: 4rem;
            }

            &__column {
                width: calc(100% / 12 * 5);
                max-width: calc(100% / 12 * 5);
                position: relative;

                &:first-child {
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 100%;
                        height: 50%;
                        z-index: -1;
                        transform: translate(0%, -50%);
                        background: url('../img/dots.png') no-repeat top right;
                    }
                }

                &:last-child {
                    margin-left: calc(100% / 12 * 2);

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: 100%;
                        height: 50%;
                        z-index: -1;
                        transform: translate(50%, 50%);
                        background: url('../img/dots.png') no-repeat bottom right;
                    }
                }

                &__item {
                    &__image {
                        padding-left: 10%;
                        padding-right: 10%;
                        height: 33vh;

                        img {
                            @include objfit();
                        }
                    }

                    &__content {
                        margin-top: 2rem;
                        position: relative;
                        padding: 0rem 10%;

                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            z-index: -1;
                            bottom: 2rem;
                            left: 0;
                            right: 0;
                            height: 125%;
                            background-color: $gray0;
                        }
                    }

                    &__title {
                        h3 {
                            font-size: ptr(34px, $fz);
                            font-weight: bold;
                            text-transform: uppercase;
                            color: $red;

                            b {
                                color: $gray1;
                            }
                        }
                    }

                    &__text {
                        padding-right: 2rem;

                        p {
                            font-family: $ff2;
                            font-size: ptr(18px, $fz);
                            line-height: 1.5;
                        }
                    }

                    &__link {
                        a {
                            background: $dark;
                            color: $light;
                            text-transform: uppercase;
                            font-weight: bold;
                            padding: 1.5rem 1.5rem;
                            text-decoration: none;
                            margin-top: 1rem;
                            margin-left: 0;
                            display: inline-block;

                            i {
                                color: $red;
                                display: inline-block;
                                margin-left: 0.5rem;
                            }

                            &:hover {
                                background-color: $red;

                                i {
                                    color: $dark;
                                    animation: balance 1s infinite;
                                }
                            }
                        }
                    }
                }
            }

        }
    }

    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* NOUS RENCONTRER + ACTUS */
    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    &.rencontrer {
        margin-top: 6rem;

        .rencontrer {
            &__columns {
                @include flex(flex, row, nowrap, flex-start, stretch);

                > div {
                    width: calc(100% / 12 * 5);
                    max-width: calc(100% / 12 * 5);
                    position: relative;

                    &:first-child {
                        margin-top: 10rem;

                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            z-index: -1;
                            transform: translate(-50%, 50%);
                            background: url('../img/dots.png') no-repeat;
                        }
                    }

                    &:last-child {
                        margin-left: calc(100% / 12 * 2);

                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 100%;
                            height: 205px;
                            z-index: 0;
                            transform: translate(0%, 0%);
                            background: url('../img/dots.png') no-repeat top right;
                        }

                        .rencontrer__column__title {
                            position: relative;
                            padding-top: 4rem;

                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                top: 0;
                                left: -20%;
                                right: -20%;
                                width: auto;
                                height: 150%;
                                z-index: -1;
                                background: $red;
                            }

                            .btn {
                                position: absolute;
                                right: 0;
                                bottom: 0;
                                background: $dark;
                                color: $light;
                                text-transform: uppercase;
                                font-weight: bold;
                                padding: 1.5rem 1.5rem;
                                text-decoration: none;
                                margin-top: 1rem;
                                display: inline-block;

                                i {
                                    color: $red;
                                    display: inline-block;
                                    margin-left: 0.5rem;
                                }

                                &:hover {
                                    background-color: $dark;

                                    i {
                                        color: $light;
                                        animation: balance 1s infinite;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            
            &__column {
                &__title {
                    h2 {
                        color: $dark;
                        text-transform: uppercase;
                    }
                }

                &__event {
                    background: $light;
                    margin-bottom: 1.5rem;

                    a {
                        padding: 1rem;
                        box-shadow: 0 2px 14px rgba($dark, .12);
                        text-decoration: none;
                        color: inherit;
                        display: block;
                        transition: $trans;

                        &:hover {
                            background-color: $gray0;
                            box-shadow: 0 2px 14px rgba($dark, .25);
                        }
                    }

                    &__title {
                        h3 {
                            text-transform: uppercase;
                            font-weight: bold;
                            color: $dark;

                            i {
                                padding: 0.5rem;
                                background: $red;
                                color: $light;
                                border-radius: 4px;
                                margin-right: 0.25rem;
                            }
                        }
                    }

                    &__date {
                        font-style: italic;
                        font-family: $ff2;
                        color: $gray1;
                        line-height: 1;
                        font-weight: 700;
                    }

                    &__text {
                        color: $gray1;
                        font-family: $ff2;
                        line-height: 1;
                    }
                }

                &__slider {
                    &__items {
                        position: relative;
                        overflow: hidden;
                    }

                    &__item {
                        background: $gray0;

                        &__image {
                            position: relative;
                            width: 100%;
                            height: 0;
                            padding-top: 45.83%;
                            overflow: hidden;

                            img {
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                @include objfit();
                            }
                        }

                        &__content {
                            padding: 2rem;
                        }

                        &__title {
                            h3 {
                                font-family: $ff1;
                                font-weight: bold;
                                text-transform: uppercase;
                            }
                        }

                        &__text {
                            p {
                                font-family: $ff2;
                                color: $gray1;
                                font-weight: 600;
                            }
                        }

                        &__link {
                            a {
                                text-decoration: none;
                                text-transform: uppercase;
                                color: $red;
                                font-weight: bold;
                                text-align: left;
                                
                            }
                        }
                    }

                    &__nav {
                        @include flex(flex, row, nowrap, flex-end, flex-start);
                        margin-top: 1rem;

                        button {
                            @include flex(flex, row, nowrap, center, center);
                            appearance: none;
                            background: $gray1;
                            border: none;
                            color: $light;
                            width: ptr(32px, $fz);
                            height: ptr(32px, $fz);
                            border-radius: 4px;
                            margin: 0rem 0rem 0rem 0.5rem;
                            cursor: pointer;
                            transition: $trans;

                            &:hover {
                                background-color: $dark;
                            }

                            &.disabled {
                                opacity: 0.5;
                            }
                        }
                    }
                }

                &.event {
                    .rencontrer__column__title {
                        h2 {
                            b {
                                color: $red;
                            }
                        }
                    }
                }

                &.news {
                    .rencontrer__column__title {
                        h2 {
                            b {
                                color: $light;
                            }
                        }
                    }
                }
            }
        }

    }

    
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* < 1500px */
/* ----------------------------------------------------- */
@media screen and (max-width: 1500px) {

    section.slider .slider__container {
        width: calc(45% - 2rem);

        .slider__title {
            padding: 10rem 1rem 0;

            h2 {
                font-size: ptr(32px, $fz);
            }
        }

        .owl-stage-outer .owl-stage .owl-item {

            .slider__container__item__title {
                font-size: ptr(32px, $fz);
            }

            .slider__container__item {
                padding: 0 1rem 4rem 1rem;
            }

            .slider__container__item__text {
                font-size: ptr(20px, $fz);
            }
        }
    }

}
/* ----------------------------------------------------- */
/* < 1200px */
/* ----------------------------------------------------- */
@media screen and (max-width: 1200px) {
    section.slider {
        // height: 60vw;

        .slider__nav {
            left: 3rem;
            bottom: 3rem;
        }

        .slider__container {
            width: calc(45% - 2rem);

            .owl-stage-outer .owl-stage .owl-item {

                .slider__container__item__title {
                    font-size: ptr(32px, $fz);
                }

                .slider__container__item {
                    padding: 0 1rem 4rem 1rem;
                }

                .slider__container__item__text {
                    font-size: ptr(20px, $fz);
                }
            }
        }
    }

    section.solutions {
        .solutions__slider__item {
            &__link {
                a {
                    padding: 1rem;
                }
            }

            &__image {
                img {
                    width: 80%;
                }
            }
        }
    }

    section.savoir-faire {
        .savoir-faire__column__item {
            &__link {
                a {
                    padding: 1rem;
                }
            }
        }
    }

    section.rencontrer {
        .rencontrer__columns {
            >div:last-child {
                .rencontrer__column__title {
                    .btn {
                        padding: 1rem;
                    }
                }
            }
        }
    }

}

/* ----------------------------------------------------- */
/* < 1100px */
/* ----------------------------------------------------- */
@media screen and (max-width: 1100px) {

    section.slider {

        .slider {
            &__nav {
                bottom: 0;
            }

            &__container {
                .owl-stage-outer .owl-stage .owl-item {
                    .slider__container__item {
                        padding: 2rem 1rem;

                        &__title {
                            font-size: ptr(28px, $fz);
                        }
                        &__text {
                            font-size: ptr(16px, $fz);
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}

/* ----------------------------------------------------- */
/* < 992px */
/* ----------------------------------------------------- */
@media screen and (max-width: 992px) {

    section.slider {
        padding-top: 7rem;
        height: auto;

        .slider {
            &__video {
                position: relative;
                left: 0;
                right: 0;
                top: 0;
                opacity: 1;
                height: 0;
                padding-top: 56.25%;

                video {
                    // position: static;
                }
            }

            &__container {
                position: relative;
                top: auto;
                bottom: auto;
                left: auto;
                right: auto;
                width: calc(100% - 0rem);

                // body.scrolled & {
                //     transform: none;
                //     opacity: 1;
                //     visibility: visible;
                // }

                .slider__title {
                    padding: 1rem;
                }

                .owl-stage-outer .owl-stage .owl-item .slider__container__item {
                    position: static;
                    transform: none;
                    width: 100%;
                    padding: 2rem 1rem;

                    &__title {
                        margin-top: 0;
                    }
                }
            }

            &__nav {
                position: absolute;
                z-index: 22;
                left: auto;
                bottom: auto;
                transform: translateY(-100%);

                body.scrolled & {
                    opacity: 1;
                }
            }
        }
    }

    section.solutions {
        margin-top: 2rem;

        .solutions__slider__item__image {
            img {
                width: 100%;
            }
        }
    }

    section.savoir-faire {
        .savoir-faire__column__item {
            &__image {
                height: 16vh;
            }
        }
    }

    section.rencontrer {
        .rencontrer__columns {
            >div:last-child {
                .rencontrer__column__title {
                    .btn {
                        position: static;
                        text-align: right;
                    }
                }
            }
        }
    }

}

/* ----------------------------------------------------- */
/* < 768px */
/* ----------------------------------------------------- */
@media screen and (max-width: 768px) {

    section.slider {
        padding-left: 0;
        padding-right: 0;
    }

    section.solutions {
        padding: 2rem;

        .solutions {
            &__title {
                h2 {
                    margin-bottom: 2rem;
                }
            }

            &__slider {
                &__container {
                    padding: 2rem 0;
                }

                &__dot {
                    &__btn {
                        width: auto;
                    }
                }

                &__item {
                    padding-left: 0;

                    &__link {
                        a {
                            padding: 1rem;
                        }
                    }
                }

            }
        }
    }

    section.savoir-faire {
        .savoir-faire {
            &__column {
                width: calc(100% / 12 * 6);
                max-width: calc(100% / 12 * 6);

                &:first-child {
                    padding-right: 0.5rem;
                }

                &:last-child {
                    margin-left: 0;
                    padding-left: .5rem;
                }

                &__item {
                    &__title {
                        h3 {
                            font-size: ptr(24px, $fz);
                        }
                    }
                }
            }
        }
    }

    section.rencontrer {
        .rencontrer {
            &__column {
                &:last-child {
                    .rencontrer {
                        &__column {
                            &__title {
                                .btn {
                                    position: static;
                                    padding: 1rem;
                                    margin-bottom: 1rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }


}

/* ----------------------------------------------------- */
/* < 480px */
/* ----------------------------------------------------- */
@media screen and (max-width: 480px) {

    section {
        &.solutions {
            margin-top: 0;
            padding: 1rem;

            .solutions {
                &__slider {
                    &__dot {
                        margin: 0rem 0.25rem;

                        &__btn {
                            width: auto;
                            padding: 0.75rem;
                            font-size: ptr(12px, $fz);
                        }
                    }

                    &__item {
                        width: 100%;

                        &__count {
                            font-size: ptr(24px, $fz);

                            b {
                                font-size: ptr(32px, $fz);
                            }
                        }

                        &__title {
                            font-size: ptr(24px, $fz);
                        }

                        &__content {
                            width: calc(100% / 1.5);
                        }
                        &__image {
                            width: calc(100% / 3);
                            @include flex(flex, column, nowrap, center, stretch);
                        }

                        &__link {
                            a {
                                padding: .75rem;
                            }
                        }
                    }
                }
            }
        }

        &.savoir-faire {
            margin-top: 2rem;

            .savoir-faire {
                &__columns {
                    flex-wrap: wrap;
                }

                &__column {
                    width: 100%;
                    max-width: 100%;

                    &:first-child { padding-right: 0;}
                    &:last-child { padding-left: 0;}

                    &__item {
                        margin-bottom: 1rem;

                        &__image {
                            height: 33vh;
                        }

                        &__link {
                            a {
                                padding: 0.75rem;
                            }
                        }
                    }
                }
            }
        }

        &.rencontrer {
            margin-top: 2rem;

            .rencontrer {
                &__columns {
                    flex-wrap: wrap;

                    > div {
                        width: 100%;
                        max-width: 100%;

                        &:first-child {
                            margin-top: 0;
                        }

                        &:last-child {
                            margin-left: 0;

                            .rencontrer__column__title {
                                padding-top: 2rem;

                                h2 {
                                    margin-top: 0;
                                    margin-bottom: 1rem;
                                }
                            }
                        }
                    }
                }

            }
        }

    }

}