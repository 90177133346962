/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* VARIABLES */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
@charset "utf-8";

/* ----------------------------------------------------- */
/* Couleurs */
/* ----------------------------------------------------- */
$light: #ffffff;
$dark: #000000;

/* Specifiques au site */
/* ----------------------------------------------------- */
$red: #d12127;
$gray: #888;
$gray0: #EFEFEF;
$gray1: #464749;
$gray2: #434A54;
$gray3: #2A2929;

$danger: #FF0000;
$warning: #FF7B03;
$success: #65A603;

$col-facebook: #43619C;
$col-google: #D8D8D8;

/* ----------------------------------------------------- */
/* FONTS */
/* ----------------------------------------------------- */
$ff1: Roboto, Arial, sans-serif;
$ff2: Asap, Verdana, sans-serif;
$ff3: 'Saira Extra Condensed', sans-serif;

$fficon: "icomoon";
$ffa: "FontAwesome";

/* ----------------------------------------------------- */
/* FONT-SIZES */
/* ----------------------------------------------------- */
$fz: 16px;
$fz_xxs: 16px;
$fz_xs: 16px;
$fz_sm: 16px;
$fz_md: 16px;
$fz_lg: 16px;
$fz_xl: 16px;

/* ----------------------------------------------------- */
/* TRANSITIONS */
/* ----------------------------------------------------- */
$trans2: all 0.4s ease-out;
$cubictrans: cubic-bezier(0.12, 0.9, 0.79, 1);
$cubic: all 1.4s $cubictrans;

$cubic2trans: cubic-bezier(0.14, 0.66, 0.28, 0.93);
$cubic2: all 0.4s $cubic2trans;

$trans: $cubic2;
/* ----------------------------------------------------- */
/* BASES PADDING & MARGIN */
/* ----------------------------------------------------- */
$base-padding: 16px;
$base-margin: 20px;

/* ----------------------------------------------------- */
/* BORDER RADIUS */
/* ----------------------------------------------------- */
$bdrs: 100rem;

/* ----------------------------------------------------- */
/* CONTAINERS */
/* ----------------------------------------------------- */
$container-width: 1650px;

/* ----------------------------------------------------- */
/* HEADER */
/* ----------------------------------------------------- */

$hdr-h: 75px;
$hdr-h_xs: 75px;
$hdr-h_sm: 120px;
$hdr-h_md: 120px;
$hdr-h_lg: 120px;
$hdr-reduced-h: 120px;

/* ----------------------------------------------------- */
/* SIDEBAR */
/* ----------------------------------------------------- */

$asd_h: 60px;
$asd_h_xl: 85px;
$asd_w_md: 30%;
$asd_w_lg: 320px;

/* ----------------------------------------------------- */
/* FOOTER */
/* ----------------------------------------------------- */

$ftr_bg: transparent;
$ftr_bg-dark: $gray;

/* ----------------------------------------------------- */
/* BREAKPOINTS */
/* ----------------------------------------------------- */
$screen-xxs: 480px;
$screen-xs: 576px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;
$screen-xl: 1600px;
$screen-xxl: 1920px;

$themePath: "/content/themes/endocrino/";
$imagePath: $themePath + "assets/images/";
$fontPath: $themePath + "assets/fonts/";

/* ----------------------------------------------------- */
/* HEXAGONE */
/* ----------------------------------------------------- */
$ratio: 1.1258;


/* ----------------------------------------------------- */
/* EASING */
/* ----------------------------------------------------- */
$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);
$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);
$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);
