.site__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    overflow: visible;
    z-index: 20;
    @include flex(flex, row, nowrap, flex-end, stretch);

    &__logo {
        position: absolute;
        z-index: 11;
        top: 0;
        left: 0;
        width: 25%;
        height: auto;
        background-color: white;
        padding: 2rem 2rem;
        box-shadow: 4px 4px 4px rgba(#000, .1);

        &__link {
            @include flex(flex, row, nowrap, center, center);
            width: 100%;
            height: 100%;

            h1 {
                margin: 0;
            }

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    /* ----------------------------------------------------- */
    /* MENU DROITE */
    /* ----------------------------------------------------- */
    &__right {
        @include flex(flex, row, nowrap, flex-end, stretch);
        width: 50%;
        padding: 2rem 2rem 2rem 0;
        position: relative;
        // background-color: $light;

        /* Nav niv 1 */
        /* ----------------------------------------------------- */
        &__nav {
            padding: 0;
            margin: 0;
            list-style: none;
            
            @include flex(flex, row, nowrap, flex-start, stretch);

            &__item {
                padding: 1rem;

                &__link {
                    @include flex(flex, row, nowrap, space-between, center);
                    text-decoration: none;
                    color: $dark;
                    text-transform: uppercase;
                    font-weight: bold;
                    white-space: nowrap;
                    position: relative;

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 100%;
                        width: 0;
                        height: 10px;
                        margin-top: 5px;
                        background-color: $red;
                        transition: $trans;
                    }

                    &:after {
                        content: '▾';
                        display: inline;
                        margin-left: 0.25rem;
                    }
                }

                /* Active */
                &.active {
                    .site__header__right__nav__item__link {
                        &:before {
                            width: 100%;
                        }
                    }

                    .site__header__right__nav2 {
                        display: block;
                    }
                }
            }
        }

        /* Nav niv 2 */
        /* ----------------------------------------------------- */
        &__nav2 {
            position: absolute;
            padding: 3rem;
            margin: 0;
            list-style: none;
            display: none;
            top: 100%;
            left: 1rem;
            right: 0;
            background: $light;
            box-shadow: 0 4px 8px rgba($dark, .25);

            &__container {
                margin-bottom: 1rem;
                @include flex(flex, row, wrap, flex-start, flex-start);
            }

            &__title {
                margin-top: 0;
                text-transform: uppercase;

                b {
                    color: $red;
                }
            }

            &__item {
                width: calc(100% / 4);

                &__link {
                    display: block;
                    font-weight: bold;
                    font-size: ptr(16px, $fz);
                    text-transform: uppercase;
                    margin-bottom: 1em;
                    text-decoration: none;
                    color: inherit;
                    overflow: hidden;
                    padding: 1rem;
                    
                    img{
                        width: 100%;
                    }

                    &__img {
                        display: block;
                        margin-bottom: 0.5rem;
                        width: 100%;
                        height: auto;
                    }

                    span {
                        display: block;
                        text-align: left;
                    }

                    &:hover {
                        color: $red;
                    }
                }
            }
        }

        /* Boutons */
        /* ----------------------------------------------------- */
        &__buttons {
            @include flex(flex, row, nowrap, flex-end, center);
        }

        &__button {
            @include flex(flex, row, nowrap, center, center);
            width: 32px;
            height: 32px;
            border-radius: 4px;
            margin: 0rem 0.5rem;
            color: $light;
            text-decoration: none;
            cursor: pointer;
            transition: $trans;

            &.black { 
                background-color: $dark;

                &:hover {
                    background-color: $red;
                }
            }
            &.red { 
                background-color: $red;

                &:hover {
                    background-color: $dark;
                }
            }
            &.gray { 
                background-color: $gray;

                &:hover {
                    background: $gray1;
                }
            }

            /* Langues */ 
            &.langues {
                position: relative;

                #btn_lang {
                    appearance: none;
                    padding: 0;
                    border: none;
                    color: $light;
                    text-transform: uppercase;
                    font-weight: bold;
                    background: none;
                    cursor: pointer;
                }

                #deroulant_lang {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    right: 0;
                    background-color: $light;
                    display: none;

                    > ul {
                        padding: 4px 0;
                        margin: 0;
                        list-style: none;
                        text-align: center;
                        box-shadow: 0px 2px 4px rgba($dark, .2);

                        > li {
                            padding: 0;
                            margin: 0;
                            list-style: none;

                            > a {
                                color: $dark;
                                text-transform: uppercase;
                                font-weight: bold;
                                text-align: center;
                                text-decoration: none;
                                line-height: 1.25;

                                &:hover {
                                    background: $red;
                                    color: $light;
                                }
                            }
                        }
                    }

                    &.on {
                        display: block;
                    }
                }
            }

            /* Recherche */
            &.recherche {
                #btn_search {
                    appearance: none;
                    padding: 0;
                    border: none;
                    color: $light;
                    text-transform: uppercase;
                    font-weight: bold;
                    background: none;
                    cursor: pointer;
                    width: ptr(32px, $fz);
                    height: ptr(32px, $fz);
                }

                #form_search {
                    width: 0;
                    overflow: hidden;
                    transition: $cubic;

                    input[type="search"] {
                        width: 100%;
                        height: 100%;
                        border: none;
                        background-color: transparent;
                        font-size: 1rem;
                        font-weight: bold;
                        color: $light;

                        ::placeholder {
                            color: $light;
                        }
                    }

                    input[type="submit"] {
                        opacity: 0;
                        visibility: hidden;
                        pointer-events: none;
                        display: none;
                    }
                }

                &.open {
                    width: auto;


                    #form_search {
                        width: 200px;
                    }
                }
            }
        }

        /* Btn burger */
        /* ----------------------------------------------------- */
        &__menu__btn {
            @include flex(flex, column, nowrap, center, center);

            #btn_menu {
                position: static;
                appearance: none;
                padding: 0;
                border: none;
                text-transform: uppercase;
                font-weight: bold;
                text-transform: uppercase;
                color: $dark;
                background: none;
                width: auto;
                display: block;
                height: auto;
                cursor: pointer;
                position: relative;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                span {
                    opacity: 1;
                    visibility: visible;
                    transition: $trans;
                }

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: -8px;
                    left: 0;
                    right: 0;
                    height: 8px;
                    width: 100%;
                    border-radius: 5px;
                    background-color: $red;
                    margin-bottom: 4px;
                    transition: $trans;
                }

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: -8px;
                    left: 0;
                    right: 0;
                    height: 8px;
                    width: 100%;
                    border-radius: 5px;
                    background-color: $red;
                    margin-top: 2px;
                    transition: $trans;
                }

                &:hover {
                    &:before,
                    &:after {
                        background-color: $dark;
                    }
                }

                &.on {
                    span {
                        opacity: 0;
                        visibility: hidden;
                    }

                    &:before {
                        top: 50%;
                        left: 50%;
                        transform-origin: 50% 50%;
                        transform: translate(-50%, -50%) rotate(-45deg);
                    }
                    &:after {
                        top: 50%;
                        left: 50%;
                        transform-origin: 50% 50%;
                        transform: translate(-50%, -50%) rotate(45deg);
                    }
                }
            }
        }

        /* Menu principal */
        /* ----------------------------------------------------- */
        &__menu {
            position: fixed;
            bottom: 0;
            right: 0;
            width: ptr(300px, $fz);
            transform: translateX(100%);
            background-color: $light;
            transition: $trans;
            box-shadow: -4px -4px 8px rgba($dark, .1);

            #deroulant {
                position: static;

                .site__header__right__menu {

                    &__nav {
                        list-style: none;
                        padding: 1rem 0;
                        margin: 0 0 1rem 0;
                    }

                    &__item {
                        list-style: none;

                        &__title {
                            text-transform: uppercase;
                            font-weight: bold;
                            font-size: ptr(20px, $fz);
                            margin-bottom: 1em;
                            cursor: pointer;

                            b {
                                color: $red;
                            }

                            &:after {
                                content: '▾';
                                display: inline;
                                margin-left: 0.5rem;
                                color: $gray;
                            }
                        }

                        > a {
                            display: block;
                        }
                    }
                }

                .site__header__right__menu2 {
                    list-style: none;
                    padding: 1rem 0 1rem 2rem;
                    margin: 0 0 1rem 0;
                    border-left: 5px solid rgba($dark, .1);
                    // display: none;

                    &__item {
                        list-style: none;

                        &__link {
                            text-decoration: none;
                            color: $dark;
                            display: block;
                            padding: 0.25rem 0;
                            font-family: $ff2;
                            line-height: 1.5;

                            &:hover {
                                color: $red;
                            }
                        }
                    }
                }
            }

            &.on {
                transform: translateX(0);
            }
        }

    }

    /* ----------------------------------------------------- */
    /* Replié */
    /* ----------------------------------------------------- */
    body.scrolled & {
        background: $light;
    }
}

/* ----------------------------------------------------- */
/* < 1522px */
/* ----------------------------------------------------- */
@media screen and (max-width: 1522px) {

    .site__header__right__nav__item__link {
        font-size: ptr(14px, $fz);
    }
    .site__header__right__nav2__item__link {
        font-size: ptr(14px, $fz);
    }

}

/* ----------------------------------------------------- */
/* < 1400px */
/* ----------------------------------------------------- */
@media screen and (max-width: 1400px) {

    .site__header__right {
        width: 60%;

        &__nav__item {
            padding: 1rem 0.25rem;
        }
    }

}

/* ----------------------------------------------------- */
/* < 1024px */
/* ----------------------------------------------------- */
@media screen and (max-width: 1024px) {

    #btn_menu {
        transform: none;
    }

    .site__header__right {
        width: auto;
    }

}

/* ----------------------------------------------------- */
/* < 992px */
/* ----------------------------------------------------- */
@media screen and (max-width: 992px) {

    .site__header {
        &__logo {
            padding: 1rem 0;
        }
    }

}

/* ----------------------------------------------------- */
/* < 830px */
/* ----------------------------------------------------- */
@media screen and (max-width: 830px) {

    .site__header {
        &__right {
            padding: 1rem;

            &__nav {
                &__item {
                    @include flex(flex, row, wrap, center, center);

                   &__link {
                        white-space: normal;
                        text-align: center;
                        max-width: ptr(100px, $fz);
                        font-size: ptr(12px, $fz);
                   }
               }
           }
        }
    }

}

/* ----------------------------------------------------- */
/* < 740px */
/* ----------------------------------------------------- */
@media screen and (max-width: 740px) {

    .site__header {

        &__logo {
            padding: 0;
            box-shadow: none;
        }

        &__right {
            padding-left: 0;
            padding-right: 1rem;

            &__nav {
                position: absolute;
                top: 100%;
                right: 0;
                width: 100vw;
                background: $light;
                box-shadow: 0 4px 4px rgba($dark, .25);

                &__item {
                    width: calc(100% / 3);

                    &__link {
                        max-width: none;
                    }
                }
            }
        }
    }

}

/* ----------------------------------------------------- */
/* < 320px */
/* ----------------------------------------------------- */
@media screen and (max-width: 320px) {

}